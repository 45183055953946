import { gql } from '@apollo/client';

const GET_INVITATION = gql`
  query getInvitation($token: String!) {
    getInvitation(token: $token) {
      emailUserInvited
      userExists
      invitedUserId
      organisationId
      projectId
      type
      permission
      project {
        id
      }
      organisation {
        key
      }

    }
  }
`;

export { GET_INVITATION };
