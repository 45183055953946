import { ButtonIconOnly, CloseIcon } from '@netfront/ui-library';
import toast from 'react-hot-toast';

import { ERROR_MESSAGE_DURATION_IN_MILLISECONDS } from './useToast.constants';
import { getToastErrorMessages } from './useToast.helpers';
import { IHandleToastErrorParams, IHandleToastSuccessParams, IUseToast } from './useToast.interfaces';

const useToast = (): IUseToast => {
  const handleToastError = ({
    durationInMilliseconds,
    error,
    shouldUseFriendlyErrorMessage = false,
    hasCloseButton = false,
  }: IHandleToastErrorParams) => {
    getToastErrorMessages({
      error,
      shouldUseFriendlyErrorMessage,
    }).forEach((errorMessage) => {
      const toastComponent = (
        <div className="c-toast__error-message">
          {errorMessage}
          {hasCloseButton && <ButtonIconOnly icon={CloseIcon} text="" onClick={() => toast.dismiss()} />}
        </div>
      );

      toast.error(toastComponent, {
        duration: hasCloseButton ? ERROR_MESSAGE_DURATION_IN_MILLISECONDS : durationInMilliseconds,
      });
    });
  };

  const handleToastCustomError = ({ durationInMilliseconds: duration, message }: IHandleToastSuccessParams) => {
    toast.error(message, {
      duration,
    });
  };

  const handleToastSuccess = ({ durationInMilliseconds: duration, message }: IHandleToastSuccessParams) => {
    toast.success(message, {
      duration,
    });
  };

  return {
    handleToastCustomError,
    handleToastError,
    handleToastSuccess,
  };
};

export { useToast };
